<template>
    <div>
        <div class="swiper" id="swiper1">
            <div class="swiper-wrapper">
                <div class="swiper-slide pointer"
                     v-for="(item, index) in carouselList"
                     :key="index"
                     @click="goDetails(item)"
                >
                    <div class="swiper-info">
                        <p class="info-title no-warp">{{item.title}}</p>
                        <p class="info-key">{{item.key_name}} Game</p>
                        <div class="info-btm">
                            <div class="btm-l">
                                <img src="@/assets/index/star.png" alt="">
                                <span>{{item.rating}}</span>
                            </div>
                            <div class="btm-play">PLAY</div>
                        </div>
                    </div>
                    <img class="heart" src="@/assets/index/heart1.png" alt="">
                    <img :src="item.logo" class="bookImg" alt="">
                </div>
            </div>
        </div>
        <adContent></adContent>
        <div class="game-body" v-for="(item,key) in gameList" :key="key">
            <div class="body-h noselect">{{key}}</div>
            <div class="body-b">
                <div
                    class="b-b-card pointer"
                    v-for="items in item"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <div class="car-info">
                        <span class="no-warp">{{items.title}}</span>
                        <div class="info-body">
                            <div class="b-l">{{items.key_name}}</div>
                            <div class="b-r">
                                <img src="@/assets/index/star.png" alt="">
                                <span>{{items.rating}}</span>
                            </div>
                        </div>
                    </div>
                    <img class="heart" src="@/assets/index/heart1.png" alt="">
                    <img class="logo" :src="items.logo" alt="">
                </div>
            </div>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import {
    getGameList,
    getGame
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: {},
                classList: [],
                sClass: '',
                carouselList:[],
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getList(){
                let cList = await getGame({count:6});
                this.carouselList = cList;
                this.$nextTick(()=>{
                    new Swiper("#swiper1", {
                        direction: "horizontal",
                        speed: 600,
                        loop: true,
                        autoplay: true,
                        observer: true,
                        observeParents: true,
                        effect: "coverflow",
                        slidesPerView: "auto",
                        centeredSlides: true,
                        coverflowEffect: {
                            rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
                            stretch: -5, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
                            depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
                            modifier: 5,
                            slideShadows: false, //开启slide阴影。默认 true。
                        },
                    });
                })
                let games = await getGameList();
                this.gameList = games;
            },
        },
    }
</script>

<style lang="less" scoped>
.pointer{
    cursor: pointer;
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.swiper {
    width: 100%;
    margin: 21px 0 20px;
    overflow: hidden;
    .swiper-slide {
        width: 296px !important;
        height: 296px;
        border-radius: 32px;
        font-size: 14px;
        text-align: center;
        line-height: 80px;
        position: relative;
        .swiper-info{
            position: absolute;
            bottom: 16px;
            left: 50%;
            transform: translateX(-50%);
            width: 264px;
            height: 98px;
            background: rgba(0,0,0,0.1);
            backdrop-filter: blur(67.96px);
            border-radius: 24px 24px 24px 24px;
            padding: 6px 16px 0;
            box-sizing: border-box;
            .info-title{
                width: 144px;
                height: 26px;
                font-family: Inter, Inter;
                font-weight: normal;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 26px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .info-key{
                height: 23px;
                font-family: Inter, Inter;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .info-btm{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 38px;
                .btm-l{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 47px;
                    height: 22px;
                    background: rgba(255,255,255,0.1);
                    border-radius: 24px 24px 24px 24px;
                    img{
                        width: 12px;
                        height: 12px;
                        margin-right: 4px;
                    }
                    span{
                        font-family: Inter, Inter;
                        font-weight: 400;
                        font-size: 10px;
                        color: #FBBF24;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }
                }
                .btm-play{
                    width: 102px;
                    height: 38px;
                    background: #E72949;
                    border-radius: 24px;
                    font-family: Inter, Inter;
                    font-weight: 400;
                    line-height: 38px;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    font-style: normal;
                    text-transform: none;
                }
            }
        }
    }
    .bookImg {
        width: 296px !important;
        height: 296px;
        border-radius: 32px;
    }
    .heart{
        width: 24px;
        height: 24px;
        position: absolute;
        top: 24px;
        right: 24px;
    }
}
.game-body{
    margin-bottom: 30px;
    .body-h{
        padding-left: 12px;
        box-sizing: border-box;
        height: 32px;
        font-family: Inter, Inter;
        font-weight: normal;
        font-size: 20px;
        color: #383838;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .body-b{
        margin-top: 20px;
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px){
            &::-webkit-scrollbar{
                height: 7px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
        .b-b-card{
            display: inline-block;
            margin: 0 8px;
            width: 167px;
            height: 167px;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            .car-info{
                position: absolute;
                width: 143px;
                height: 60px;
                background: rgba(0,0,0,0.1);
                backdrop-filter: blur(67.96px);
                border-radius: 16px;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                padding: 6px 16px 0;
                box-sizing: border-box;
                >span{
                    display: block;
                    font-family: Inter, Inter;
                    font-weight: normal;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 26px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                }
                .info-body{
                    display: flex;
                    justify-content: space-between;
                    .b-l{
                        font-family: Inter, Inter;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }
                    .b-r{
                        display: flex;
                        align-items: center;
                        span{
                            font-family: Inter, Inter;
                            font-weight: 400;
                            color: #FBBF24;
                            text-align: left;
                            font-style: normal;
                            text-transform: none;
                        }
                        img{
                            width: 16px;
                            height: 16px;
                            margin-right: 4px;
                        }
                    }
                }
            }
            .logo{
                width: 100%;
                height: 100%;
            }
            .heart{
                width: 24px;
                height: 24px;
                position: absolute;
                top: 16px;
                right: 16px;
            }
        }
    }
}
.game-blurb{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>