<template>
    <div style="padding: 0 15px;box-sizing: border-box">
        <div class="game-body">
            <div class="body-h">
                <div class="noselect">{{ title }}</div>
            </div>
            <div class="cont">
                <div
                    class="b-b-card pointer"
                    v-for="items in gameList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <div class="car-info">
                        <span class="no-warp">{{items.title}}</span>
                        <div class="info-body">
                            <div class="b-l">{{items.key_name}}</div>
                            <div class="b-r">
                                <img src="@/assets/index/star.png" alt="">
                                <span>{{items.rating}}</span>
                            </div>
                        </div>
                    </div>
                    <img class="heart" src="@/assets/index/heart1.png" alt="">
                    <img class="logo" :src="items.logo" alt="">
                </div>
                <span v-for="item in spanNum" :key="item"></span>
            </div>
        </div>
        <div class="game-body" v-if="showMore">
            <div class="body-h">
                <div class="noselect">more fun games</div>
            </div>
            <div class="cont">
                <div
                    class="b-b-card pointer"
                    v-for="items in moreList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <div class="car-info">
                        <span class="no-warp">{{items.title}}</span>
                        <div class="info-body">
                            <div class="b-l">{{items.key_name}}</div>
                            <div class="b-r">
                                <img src="@/assets/index/star.png" alt="">
                                <span>{{items.rating}}</span>
                            </div>
                        </div>
                    </div>
                    <img class="heart" src="@/assets/index/heart1.png" alt="">
                    <img class="logo" :src="items.logo" alt="">
                </div>
                <span v-for="item in spanNum" :key="item"></span>
            </div>
        </div>
        <div class="class-info" v-if="!showMore">
            {{ intro }}
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { scrollToTop, isMobile } from '@/utils/api'
import { getGameByKey, searchGame, getGame  } from "@/api/games"
import classInfo from "@/utils/classInfo"

    export default {
        data() {
            return {
                title: '',
                gameList: [],
                moreList: [],
                spanNum:0,
                showMore: false,
                intro: ''
            }
        },
        mounted() {
            if(isMobile()){
                this.spanNum = 3-2;
            }else{
                this.spanNum = 11-2;
            }

            scrollToTop(0);
            eventBus.$on('searchgame',name=>{
                let {id,key} = name;
                this.getGameList(id,key)
            })
            let {id,key} = this.$route.query;
            this.getGameList(id,key);
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getGameList(id,key){
                let games = [];
                let title = '';
                if(id=='search'){
                    games = await searchGame({q:key});
                    title = `search found "${key}"`;
                    this.moreList = await getGame({count:20});
                    this.showMore = true;
                }else if(id=='more'){
                    this.intro = classInfo.find(item => item.name === key).intro;
                    if(key === 'All'){
                        games = await getGame();
                    }else{
                        games = await getGameByKey({category:key});
                    }
                    title = key + ' games';
                    this.showMore = false;
                }else{
                    console.log(key);
                }
                this.gameList = games;
                this.title = title;
            }
        },
    }
</script>

<style lang="less" scoped>
.pointer{
    cursor: pointer;
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.body-h{
    margin-top: 15px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    line-height: 32px;
    text-align: left;
    font-style: normal;
    display: flex;
    justify-content: space-between;
}
.cont{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 22px;
    .b-b-card{
        display: inline-block;
        @media (max-width: 768px){
            margin: 5px 0;
        }
        @media (min-width: 768px){
            margin: 5px;
        }
        width: 167px;
        height: 167px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        .car-info{
            position: absolute;
            width: 143px;
            height: 60px;
            background: rgba(0,0,0,0.1);
            backdrop-filter: blur(67.96px);
            border-radius: 16px;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            padding: 6px 16px 0;
            box-sizing: border-box;
            >span{
                display: block;
                font-family: Inter, Inter;
                font-weight: normal;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 26px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .info-body{
                display: flex;
                justify-content: space-between;
                .b-l{
                    font-family: Inter, Inter;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                }
                .b-r{
                    display: flex;
                    align-items: center;
                    span{
                        font-family: Inter, Inter;
                        font-weight: 400;
                        color: #FBBF24;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }
                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }
        }
        .logo{
            width: 100%;
            height: 100%;
        }
        .heart{
            width: 24px;
            height: 24px;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
    >span{
        @media (min-width: 768px){
            margin: 5px;
        }
        width: 167px;
    }
}
</style>