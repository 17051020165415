<template>
    <div style="padding: 0 10px;box-sizing: border-box">
        <div class="info-top" v-if="isPlay">
            <div class="b-b-card noselect">
                <div class="car-info">
                    <span class="no-warp">{{gameInfo.title}}</span>
                    <div class="info-body">
                        <div class="b-l">{{gameInfo.key_name}}</div>
                        <div class="b-r">
                            <img src="@/assets/index/star.png" alt="">
                            <span>{{gameInfo.rating}}</span>
                        </div>
                    </div>
                </div>
                <img class="heart" src="@/assets/index/heart1.png" alt="">
                <img class="logo" :src="gameInfo.logo" alt="">
            </div>
            <div class="play pointer" @click="()=>{isPlay = false}">PLAY</div>
        </div>
        <div class="gamewin" v-else>
            <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
        </div>
        <div class="info-txt noselect">
            <div class="title">Description:</div>
            <div class="cont warp-6">
                {{ gameInfo.synopsis }}
            </div>
        </div>
        <div class="rec-cont">
            <div class="title">More Games</div>
            <div class="rec-body">
                <div
                    class="rec-card pointer"
                    v-for="item in gameList"
                    :key="item.id"
                    @click="goDetails"
                >
                    <img :src="item.logo" alt="">
                    <div class="no-warp card-title">{{item.title}}</div>
                    <div class="card-btm">
                        <img src="@/assets/index/heart1.png" alt="">
                        <div class="rec-play">PLAY</div>
                    </div>
                </div>
                <span v-for="i in spanNum" :key="i"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 10-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game,similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },

            goDetails(item){
                this.gameInfo = item;
                this.isPlay = true;
                scrollToTop(0);

            }
        },
    }
</script>

<style lang="less" scoped>
.info-top{
    margin: 20px 0 0;
    .b-b-card{
        margin: 0 auto 21px;
        width: 240px;
        height: 240px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        .car-info{
            position: absolute;
            width: 205px;
            height: 86px;
            background: rgba(0,0,0,0.1);
            backdrop-filter: blur(67.96px);
            border-radius: 16px;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            padding: 16px 14px;
            box-sizing: border-box;
            span{
                display: block;
                font-family: Inter, Inter;
                font-weight: normal;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 26px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .info-body{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .b-l{
                    font-family: Inter, Inter;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                }
                .b-r{
                    display: flex;
                    align-items: center;
                    span{
                        font-family: Inter, Inter;
                        font-weight: 400;
                        color: #FBBF24;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }
                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }
        }
        .logo{
            width: 100%;
            height: 100%;
        }
        .heart{
            width: 24px;
            height: 24px;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
    .play{
        margin: 0 auto;
        width: 300px;
        height: 40px;
        background: #FF8D8D;
        border-radius: 10px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        text-transform: none;
    }
}
.gamewin{
    width: 100%;
    height: calc(100vh - 111px);
    iframe{
        width: 100%;
        height: 100%;
    }
}
.info-txt{
    margin: 17px 0 29px;
    .title{
        margin-bottom: 11px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #273253;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .cont{
        font-family: Poppins, Poppins;
        font-weight: 400;
        font-size: 16px;
        color: #505050;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}
.rec-cont{
    .title{
        margin-bottom: 12px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #273253;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .rec-body{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .rec-card{
            @media (min-width: 768px){
                margin: 5px;
            }
            border-radius: 10px;
            width: 107px;
            img{
                width: 107px;
                height: 107px;
                border-radius: 10px;
            }
            .card-title{
                margin: 4px 0 3px;
                font-family: Inter, Inter;
                font-weight: bold;
                font-size: 14px;
                color: #273253;
                line-height: 18px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .card-btm{
                display: flex;
                justify-content: space-between;
                align-items: center;
                img{
                    width: 15px;
                    height: 15px;
                }
                .rec-play{
                    width: 47px;
                    height: 15px;
                    background: #FF8D8D;
                    border-radius: 10px;
                    font-family: Inter, Inter;
                    font-weight: bold;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 15px;
                    text-align: center;
                    font-style: normal;
                    text-transform: none;
                }
            }
        }
        span{
            @media (min-width: 768px){
                margin: 5px;
            }
            width: 107px;
        }
    }
}
.pointer{
    cursor: pointer;
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-6{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>