<template>
    <div>
        <div class="header">
            <img class="logo pointer" src="@/assets/index/logo.png" alt="" @click="goIndex">
            <div class="search">
                <input
                    v-if="showInput"
                    type="text"
                    v-model="searchText"
                    placeholder="keywords"
                >
                <img
                    class="pointer"
                    src="@/assets/index/search.png"
                    alt=""
                    @click="searchGame"
                >
            </div>
        </div>
        <div class="top-class">
            <div
                class="class-icon pointer"
                v-for="item in classList"
                :key="item.name"
                @click="selectClass(item.name)"
            >
                <img :src="sClass==item.name?item.surl:item.url" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import {getClassList} from "@/api/games";
import classList from "@/utils/classList";
import eventBus from "@/utils/eventBus";
    export default {
        data() {
            return {
                showMenu: false,
                showInput: false,
                searchText: '',
                classList:[],
                sClass: ''
            }
        },
        mounted() {
            this.getClass();
        },
        methods: {
            searchGame(){
                if(this.showInput){
                    let obj = {
                        id: 'search',
                        key: this.searchText
                    }
                    if(this.$route.name !== 'selectGame'){
                        this.$router.push({
                            path: 'selectGame',
                            query: obj
                        })
                    }else{
                        eventBus.$emit('searchgame',obj)
                    }
                }else{
                    this.showInput = true;
                }
            },
            async getClass(){
                let list = await getClassList();
                list.forEach((item,i) => {
                    let data = classList.find(items=> item.key_name == items.name);
                    if(data){
                        list.splice(i,1,data);
                    }
                })
                this.classList = list;
            },
            selectClass(key){
                if(this.sClass === key) return
                this.sClass = key;
                let obj = {
                    id:'more',
                    key
                }
                if(this.$route.name !== 'selectGame'){
                    this.$router.push({
                        path: 'selectGame',
                        query: obj
                    })
                }else{
                    eventBus.$emit('searchgame',obj)
                }
            },
            goIndex(){
                if(this.$route.path !== '/'){
                    this.$router.push('/');
                }else{
                    this.$router.go(0)
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.pointer{
    cursor: pointer;
}
.header{
    display: flex;
    //height: 50px;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFC;
    position: fixed;
    top: 0;
    z-index: 999;
    .logo{
        width: 157px;
        height: 43px;
    }
    .search{
        padding-left: 14px;
        box-sizing: border-box;
        height: 44px;
        background: #FFFFFF;
        border-radius: 22px;
        display: flex;
        align-items: center;
        input{
            width: 170px;
            height: 44px;
            border: none;
            outline: none;
        }
        img{
            width: 43px;
            height: 44px;
            background: #FFFFFF;
        }
    }
}
.top-class{
    width: 100%;
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    @media (min-width: 768px){
        &::-webkit-scrollbar{
            height: 7px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);
        }
    }
    .class-icon{
        margin-right: 17px;
        img{
            height: 56px;
        }
    }
}
</style>